import { ErrorMessage, FastField, Form, Formik } from 'formik'
import React from 'react'
import ImageUploader from '../Form/ImageUploader'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup';
import { useDispatch,useSelector } from 'react-redux'
import { addInstructor } from '../../store/InstructorSetup'
import TextError from '../../TextError'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import { get_user_name } from '../../EncDec'
function InstructorAdd({openPopUP,setOpenPopUp}) {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.entities.instructor?.loading)
    const initialValues={
        first_name:'',
        middle_name:'',
        last_name:'',
        image:'',
        experience:'',
        mobile_no:'',
        email:'',
        gender:'',
        instructor_short_desc:''
    }
    const validationSchema= Yup.object().shape({
        first_name: Yup.string().required().min(3).max(50).label("first name"),
        // middle_name: Yup.string().required().min(3).max(50).label("middle name"),
        last_name: Yup.string().required().min(3).max(50).label("last name"),
        image:Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("instructor image"),
        experience:Yup.string().required().min(5).max(100).label("instructor experience"),
        instructor_short_desc:Yup.string().required().min(10).max(650).label("about instructor"),
        // mobile_no:Yup.number().required().label("mobile no").min(10).max(11),
        email:Yup.string().email().required(),
        gender: Yup.string().required().label('gender'),
        
    })
    const handleSubmit = (values,submitProps)=>{
        let formData = new FormData();        
        formData.append('first_name',values.first_name);
        formData.append('last_name',values.last_name);
        formData.append('middle_name',values.middle_name);
        formData.append('experience',values.experience);
        formData.append('image',values.image,values.image.name);
        formData.append('mobile_no',values.mobile_no);
        formData.append('email',values.email);
        formData.append('gender',values.gender)
        formData.append('gender',values.instructor_short_desc)
        formData.append('created_by',get_user_name());
        
        dispatch(addInstructor(formData));
        submitProps.setSubmitting(false)
        submitProps.resetForm();
    }
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ]; 
  return (
    <FormPopUp openPopup={openPopUP} setOpenPopup={setOpenPopUp} title="Add Instructor">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        >
            {
                ({isSubmitting,values})=>(
                    // <Card cardTitle="Add Instructors">
                    <Form>
                        {/* <div className='card-body'> */}
                        <div className='row'>
                        <div className='col-md-4 form-group item'>
                                <label className='required-label'>First Name<span className="errors">*</span>:</label>
                                <FastField type="text" name="first_name" id="first_name" placeholder="first name" className='form-control'/>
                                <ErrorMessage name="first_name" component={TextError} />
                        </div>
                        <div className='col-md-4 form-group item'>
                                <label className='required-label'>Middle Name:</label>
                                <FastField type="text" name="middle_name" id="middle_name" placeholder="middle name" className='form-control'/>
                                <ErrorMessage name="middle_name" component={TextError} />
                        </div>
                        <div className='col-md-4 form-group item'>
                                <label className='required-label'>Last Name<span className="errors">*</span>:</label>
                                <FastField type="text" name="last_name" id="last_name" placeholder="last name" className='form-control'/>
                                <ErrorMessage name="last_name" component={TextError} />
                        </div>
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Email<span className="errors">*</span>:</label>
                                <FastField type="email" name="email" id="email" placeholder="email" className='form-control'/>
                                <ErrorMessage name="email" component={TextError} />
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Mobile No<span className="errors">*</span>:</label>
                                <FastField type="text" name="mobile_no" id="mobile_no" placeholder="mobile no" className='form-control'/>
                                <ErrorMessage name="mobile_no" component={TextError} />
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>gender<span className="errors">*</span>:</label>
                                <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', width:'83px', marginBottom:'10px'}}>
                                <FastField type="radio" name="gender" id="gender"  className='form-control' value="male"/> 
                                <label>male</label>

                                </div>
                                <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', width:'100px', marginBottom:'10px'}}>
                                <FastField type="radio" name="gender" id="gender"  className='form-control' value="female"/> 
                                <label>female</label>

                                </div>
                                <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', width:'100px'}}> 
                                <FastField type="radio" name="gender" id="gender"  className='form-control' value="others"/> 
                                <label>others</label>
                                </div>
                                <ErrorMessage name="gender" component={TextError} />
                        </div>
                        <ImageUploader
                        name="image"
                        label="instructor image" 
                        accept="image/jpg, image/gif,image/jpeg,image/png"                       
                        />
                        <div className='form-group item'>
                                <label className='required-label'>Instructot BIO<span className="errors">*</span>:</label>
                                <FastField as="textarea" rows="7" name="experience" id="experience" placeholder="about Instructor" className='form-control'/>
                                <div className="text-counter">
                                    {values.experience ? values.experience.length : 0}/100
                                </div>
                                <ErrorMessage name="experience" component={TextError} />
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>About Instructors<span className="errors">*</span>:</label>
                                <FastField as="textarea" rows="7" name="instructor_short_desc" id="instructor_short_desc" placeholder="about Instructor" className='form-control'/>
                                <div className="text-counter">
                                    {values.instructor_short_desc ? values.instructor_short_desc.length : 0}/650
                                </div>
                                <ErrorMessage name="instructor_short_desc" component={TextError} />
                        </div>
                    {/* </div> */}
                    {/* <div className='card-footer'> */}
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}Save</button>
                    {/* </div> */}
                    </Form>
                    // </Card>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default InstructorAdd