import React from 'react'
import ReactPlayer from 'react-player'
function ReactVideoPlayers({videoUrl,controls,playing}) {
  return (
    <ReactPlayer
    url={videoUrl}
    height='500px'
    width="100%"
    controls={controls}
    playing={playing}
    />
  )
}

export default ReactVideoPlayers