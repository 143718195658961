import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {  loadLessonSelect,  } from '../../store/LessonSetup';
import TextError from '../../TextError';
import Card from '../Card/Card';
import * as Yup from 'yup';
import CkeditorField from '../Form/CkeditorField';
import { getInstructorSelect } from '../../store/InstructorSetup';
import { addSubTopic,loadSubTopicOrderSelectById } from '../../store/SubTopicSetup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import InputMask from 'react-input-mask'
import QuillEditor from '../Form/QuillEditor';
import JoditEditors from '../Form/JoditEditors';
function SubTopicAdd({fetchData,setFetchData}) {
    const params = useParams()
    const [lessonId,setLessonId] = useState(params?.slug)
    const [quillValue,setQuillValue] = useState('')
    const [joditValue,setJoditValue] = useState('')
    const [joditVideoValue,setJoditVideoValue] = useState('')
    const lesson = useSelector(state => state.entities.lesson?.getData?.data)
    const instructor = useSelector(state => state.entities.instructor?.getData?.data)
    const errors = useSelector(state => state.entities.subTopic?.error?.data)
    const subtopic_order = useSelector(state => state.entities.subTopic?.subTopicOrderSelect?.data)
    const loading = useSelector(state => state.entities.subTopic?.loading)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getInstructorSelect())
        dispatch(loadLessonSelect())
        dispatch(loadSubTopicOrderSelectById(lessonId))
    },[params,dispatch])
   
    const initialValues = {
        topic_title:'',
        lesson:lessonId,
        sub_topic_order:'',
        video_type:'',
        em_video_url:'',
        video_url:'',
        mob_video_url:'',
        instructors:'',
        video_duration:'',
        preview_status:'',
        preview_video_url:'',
        page_type:'',
        host_type:'',
        description_editor_type:'',
        sub_topic_desc:'',
        created_by: localStorage.getItem('username')
    }
    const handleLessonChange = async(lessonId)=>{
        setLessonId(lessonId)
       await dispatch(loadSubTopicOrderSelectById(lessonId))
    }
    const validationSchema = Yup.object().shape({
        topic_title: Yup.string().required().min(3).max(100).label('Topic title'),
        lesson: Yup.string().required().label('lesson'),
        page_type:Yup.string().required().label('page type'),
        sub_topic_order: Yup.string().required().label('Order Sequence'),
        video_url:Yup.string().when("video_type",{
            is:(pageType)=> pageType === 'url',
            then:Yup.string().required().label('video url')
        }),
        em_video_url:Yup.string().when("video_type",{
            is:(pageType)=> pageType === 'em',
            then:Yup.string().required().label('embed video url')
        }),
        mob_video_url:Yup.string().when("page_type",{
            is:(pageType)=> pageType === 'multimedia',
            then:Yup.string().required().label('app video url')
        }),
        host_type:Yup.string().when("page_type",{
                    is:(pageType)=> pageType === 'multimedia',
                    then:Yup.string().required().label('host type')
                }),
        video_duration:Yup.string().when("page_type",{
            is:(pageType)=> pageType === 'multimedia',
            then:Yup.string().required().label('video duration')
        }),      
        preview_status:Yup.string().when("page_type",{
            is:(pageType)=> pageType === 'multimedia',
            then:Yup.string().required().label('preview status')
        }),      
        sub_topic_desc:Yup.string().when("page_type",{
            is:(pageType)=> pageType === 'plain_text',
            then:Yup.string().required().min(30).label('description')
        }),     
        preview_video_url:Yup.string().when("preview_status",{
            is:(pageType)=> pageType === 'y',
            then:Yup.string().required().label('preview video url')
        }),     
        
    })
         
    const handleSubmit = async(values,submitProps)=>{  
                 
        values.created_by = localStorage.getItem('username');
        await dispatch(addSubTopic(values));
        await dispatch(loadSubTopicOrderSelectById(lessonId))
        submitProps.setSubmitting(false);
        submitProps.resetForm();
    }
    const preview = [
        {
            'value':'y',
            'label':'True',
        },
        {
            'value':'n',
            'label':'False',
        },
        
    ]
    const PAGE_TYPE = [
        {
            'value':'plain_text',
            'label':'Plaint Text',
        },
        {
            'value':'multimedia',
            'label':'Multimedia',
        },
        
    ]
    const HOST_TYPE = [
        {
            'value':'others',
            'label':'OTHERS',
        },
        {
            'value':'yt',
            'label':'YOUTUBE',
        },
        
    ]
    const VIDEO_TYPE = [
        {
            'value':'em',
            'label':'embed',
        },
        {
            'value':'url',
            'label':'url',
        },
        
    ]
    const DESCRIPTION_TYPE = [
        {
            'value':'ck',
            'label':'ck',
        },
        {
            'value':'quill',
            'label':'quill',
        },
        {
            'value':'jodit',
            'label':'jodit',
        },
        
    ]
    console.log("value",joditValue)
  return (
      <Card cardTitle="Sub Topic Add">
          {loading && <LoaderSpinner/>}
          <div className='card-body'>
            <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
            >
                {
                    ({isSubmitting,values})=>(                        
                        <Form>
                            
                            <div className='form-group item'>
                                <label className='required-label'>Topic Title<span className='errors'>*</span></label>
                                <FastField type="text" name="topic_title" id="topic_title" placeholder="topic title" className='form-control'/>
                                <ErrorMessage name="topic_title" component={TextError} />
                                {errors?.errors && <div className='errors'>this topic title already exists</div>}
                                {errors?.topic_title && <div className='errors'>{errors?.topic_title}</div>}
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select Parent<span className='errors'>*</span></label>
                                <Field as="select" name="lesson" id="lesson" placeholder="" value={lessonId} className='form-control' onChange = {(e)=>handleLessonChange(e.target.value)}>
                                    <option name="subcourse_id" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        lesson && lesson?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item?.lesson_title}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="lesson" component={TextError} />
                                {errors?.lesson && <div className='errors'>{errors?.lesson}</div>}
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select Order<span className='errors'>*</span></label>
                                <Field as="select" name="sub_topic_order" id="sub_topic_order" placeholder="" className='form-control'>
                                    <option name="subcourse_id" value="">select order</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        subtopic_order && subtopic_order?.map((item,index)=>{
                                            if(typeof item === 'object'){
                                                return(

                                                    <option key={item?.iid} value={item?.sub_topic_order}>{`${item?.sub_topic_order}${item?.topic_title}`}</option>
                                                )
                                            }else{
                                                return(
                                                    <option key={index} value={item}>{`${item}-subtopic`}</option>

                                                )
                                            }
                                        }
                                        )
                                    }
                                </Field>
                                <ErrorMessage name="sub_topic_order" component={TextError} />
                                {errors?.sub_topic_order && <div className='errors'>{errors?.sub_topic_order}</div>}
                            </div>
                           
                            
                            <div className='form-group item'>
                                <label className='required-label'>Instructors</label>
                                <Field as="select" name="instructors" id="instructors" placeholder="" className='form-control'>
                                    <option name="instructors" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        instructor && instructor?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item?.instructor_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="instructors" component={TextError} />
                                {errors?.instructors && <div className='errors'>{errors?.instructors}</div>}
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Page Type<span className='errors'>*</span> </label>
                                <Field as="select" name="page_type" id="page_type" placeholder="" className='form-control'>
                                    <option name="page_type" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        PAGE_TYPE && PAGE_TYPE?.map((item,index)=>(
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="page_type" component={TextError} />
                                {errors?.page_type && <div className='errors'>{errors?.page_type}</div>}
                            </div> 
                            {
                                values.page_type ==='multimedia'?
                                <>
                                <div className='form-group item'>
                                <label className='required-label'>Host Type<span className='errors'>*</span> </label>
                                <Field as="select" name="host_type" id="host_type" placeholder="" className='form-control'>
                                    <option name="host_type" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        HOST_TYPE && HOST_TYPE?.map((item,index)=>(
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="host_type" component={TextError} />
                                {errors?.host_type && <div className='errors'>{errors?.host_type}</div>}
                                </div>
                                <div className='form-group item'>
                                <label className='required-label'>Video Type<span className='errors'>*</span> </label>
                                <Field as="select" name="video_type" id="video_type" placeholder="" className='form-control'>
                                    <option name="video_type" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        VIDEO_TYPE && VIDEO_TYPE?.map((item,index)=>(
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="video_type" component={TextError} />
                                {errors?.video_type && <div className='errors'>{errors?.video_type}</div>}
                                </div> 
                                
                                {
                                    values?.video_type === 'em' &&
                                    <>
                                    <JoditEditors name='em_video_url' content={joditVideoValue} setContent={setJoditVideoValue}/>
                                    <ErrorMessage name='em_video_url' component={TextError}/>
                                    {errors?.em_video_url && <div className='errors'>{errors?.em_video_url}</div>}
                                    </>
                                }
                                {
                                values?.video_type === 'url' &&
                                <div className='form-group item'>
                                    <label className='required-label'>video url<span className='errors'>*</span></label>
                                    <FastField type="text" name="video_url" id="video_url" placeholder="url https://" className='form-control'/>
                                    <ErrorMessage name="video_url" component={TextError} />
                                    {errors?.video_url && <div className='errors'>{errors?.video_url}</div>}
                                </div>
                                    
                                }
                            <div className='form-group item'>
                                <label className='required-label'>App video url<span className='errors'>*</span></label>
                                <FastField type="text" name="mob_video_url" id="mob_video_url" placeholder="url https://" className='form-control'/>
                                <ErrorMessage name="mob_video_url" component={TextError} />
                                {errors?.mob_video_url && <div className='errors'>{errors?.mob_video_url}</div>}
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>video Duration<span className='errors'>*</span></label>
                                <Field 
                                render = {({field})=>(
                                    <InputMask
                                    {...field}
                                    id="video_duration" 
                                    mask="99:99:99"
                                    placeholder='enter video duration'
                                    className='form-control'
                                    />
                                )}                               
                                name="video_duration" 
                                id="video_duration"                                 
                                />
                                <ErrorMessage name="video_duration" component={TextError} />
                                {errors?.video_duration && <div className='errors'>{errors?.video_duration}</div>}
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Enable Preview?<span className='errors'>*</span> </label>
                                <Field as="select" name="preview_status" id="preview_status" placeholder="" className='form-control'>
                                    <option name="preview_status" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        preview && preview?.map((item,index)=>(
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="preview_status" component={TextError} />
                                {errors?.preview_status && <div className='errors'>{errors?.preview_status}</div>}
                            </div>
                                </>:''
                            }  
                            {
                                values.preview_status === 'y'?
                                <div className='form-group item'>
                                <label className='required-label'>preview video url<span className='errors'>*</span></label>
                                <FastField type="text" name="preview_video_url" id="preview_video_url" placeholder="url https://" className='form-control'/>
                                <ErrorMessage name="preview_video_url" component={TextError} />
                                {errors?.preview_video_url && <div className='errors'>{errors?.preview_video_url}</div>}
                                </div>
                                :''
                            }
                             <div className='form-group item'>
                                <label className='required-label'>Description Editor Type<span className='errors'>*</span> </label>
                                <Field as="select" name="description_editor_type" id="description_editor_type" placeholder="" className='form-control'>
                                    <option name="description_editor_type" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        DESCRIPTION_TYPE && DESCRIPTION_TYPE?.map((item,index)=>(
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="description_editor_type" component={TextError} />
                                {errors?.description_editor_type && <div className='errors'>{errors?.description_editor_type}</div>}
                            </div> 
                            {
                                values.description_editor_type === 'ck' &&
                                <div className='form-group item'>
                                    <label>Topic Description:{values.page_type === 'plain_text'?<span className='errors'>*</span>:''}</label>
                                <CkeditorField name="sub_topic_desc" />
                                <ErrorMessage name='sub_topic_desc' component={TextError}/>
                                {errors?.sub_topic_desc && <div className='errors'>{errors?.sub_topic_desc}</div>}
                                </div>

                            } 
                            {
                                values.description_editor_type === 'quill' &&
                                <div className='form-group item'>
                                    <label>Topic Description:{values.page_type === 'plain_text'?<span className='errors'>*</span>:''}</label>
                                <QuillEditor value={quillValue} setValue={setQuillValue} name="sub_topic_desc" />
                                <ErrorMessage name='sub_topic_desc' component={TextError}/>
                                {errors?.sub_topic_desc && <div className='errors'>{errors?.sub_topic_desc}</div>}
                                </div>
                            }                       
                            {
                                values.description_editor_type === 'jodit' &&
                                <div className='form-group item'>
                                    <label>Topic Description:{values.page_type === 'plain_text'?<span className='errors'>*</span>:''}</label>
                                <JoditEditors content={joditValue} setContent={setJoditValue} name="sub_topic_desc" />
                                <ErrorMessage name='sub_topic_desc' component={TextError}/>
                                {errors?.sub_topic_desc && <div className='errors'>{errors?.sub_topic_desc}</div>}
                                </div>
                            }                       
                            {/* <QuillEditor value={quillValue} setValue={setQuillValue}/>
                            
                            <JoditEditors content={joditValue} setContent={setJoditValue} /> */}
                            <div className='card-footer'>
                                 <button className='btn btn-flat btn-success' disabled={isSubmitting}>save</button>       
                            </div>
                        </Form>                       
                    )
                }
            </Formik>
          </div>
      </Card>
  );
}

export default SubTopicAdd;
