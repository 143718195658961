import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLessonByIds,editLesson, loadLessonOrderSelectById } from '../../store/LessonSetup';
import { loadSubCourse } from '../../store/SubCourseSetup';
import TextError from '../../TextError';
import Card from '../Card/Card';
import * as Yup from 'yup'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import { loadMainTopic } from '../../store/MainTopicSetup';
function LessonEdit({fetchData,setFetchData}) {
    const params = useParams() 
    const [mainTopicId,setMainTopicId] = useState()  
    const subcourse = useSelector(state => state.entities.subcourse.result?.data)
    const lesson = useSelector(state => state.entities.lesson.getDataById?.data)
    const lessonOrder = useSelector(state => state.entities.lesson?.lessonOrderSelect?.data)
    const mainTopic = useSelector(state => state.entities.mainTopic?.result?.data)
    const errors = useSelector(state => state.entities.lesson?.error?.data)
    // const errors = useSelector(state => state.entities.lesson.error?.data)
    const loading = useSelector(state => state.entities.lesson.loading)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(loadMainTopic())
        dispatch(getLessonByIds(params?.slug))
        dispatch(loadLessonOrderSelectById(lesson?.main_topic_iid))
    },[params])
    const initialValues = {
        lesson_title:lesson?.lesson_title,
        main_topic_id:mainTopicId?mainTopicId:lesson?.main_topic_iid,
        lesson_order:lesson?.lesson_order
    }
    const handleSubmit = (values,submitProps) => {
        dispatch(editLesson(values))        
        if(!loading){
            submitProps.setSubmitting(false);
        }
    }
    const validationSchema = Yup.object().shape({
        lesson_title:Yup.string().required().label('lesson title').min(3).max(50),
        main_topic_id:Yup.string().required().label('parent'),
        lesson_order: Yup.string().required().label('lesson order'),
    })
    const handleMainTopicChange =  async (mainTopicId)=>{
        setMainTopicId(mainTopicId)
        await dispatch(loadLessonOrderSelectById(mainTopicId))
    }
  return (
      <Card cardTitle="Lesson Edit">
          {loading && <LoaderSpinner/>}
          <div className='card-body'>
            <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <div className='form-group item'>
                                <label className='required-label'>Lesson Title</label>
                                <FastField type="text" name="lesson_title" id="lesson_title" placeholder="lesson title" className='form-control'/>
                                <ErrorMessage name="lesson_title" component={TextError} />
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select Parent<span className="errors">*</span></label>
                                <Field as="select" name="subcourse_main_topic_id" id="subcourse_main_topic_id" value={mainTopicId?mainTopicId:lesson?.main_topic_iid} placeholder="" className='form-control' onChange={(e)=>handleMainTopicChange(e.target.value)}>
                                    <option name="subcourse_main_topic_id" value="">select Options</option>
                                    {
                                        mainTopic && mainTopic?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.topic_title}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="subcourse_main_topic_id" component={TextError} />
                                {errors?.subcourse_main_topic_id && <div className='errors'>{errors?.subcourse_main_topic_id}</div> }
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select Lesson Order<span className="errors">*</span></label>
                                <Field as="select" name="lesson_order" id="lesson_order"  placeholder="" className='form-control' >
                                    <option name="lesson_order" value="">select order</option>
                                    {
                                        lessonOrder && lessonOrder?.map((item,index)=>{
                                            if(typeof item === 'object'){
                                                return(
                                                    <option key={index} value={item?.lesson_order}>{`${item?.lesson_order}-${item?.lesson_title}`}</option>

                                                )
                                            }else{

                                                return <option key={index} value={item}>{`${item}-Lesson`}</option>
                                            }
                                        })
                                    }
                                </Field>
                                <ErrorMessage name="lesson_order" component={TextError} />
                                {errors?.lesson_order && <div className='errors'>{errors?.lesson_order}</div> }
                            </div>
                            <div className='card-footer'>
                                 <button className='btn btn-flat btn-success' disabled={isSubmitting}>save</button>       
                            </div>
                        </Form>
                    )
                }
            </Formik>
          </div>
      </Card>
  );
  
}

export default LessonEdit;
