import { ErrorMessage, FastField, Form, Formik } from 'formik'
import React from 'react'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup';
import { useDispatch,useSelector } from 'react-redux'
import { addInstructor, editInstructor } from '../../store/InstructorSetup'
import TextError from '../../TextError'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
function InstructorEdit({openPopUP,setOpenPopUp,data}) {
    console.log("data",data)
    const dispatch = useDispatch();
    const loading = useSelector(state => state.entities.instructor?.loading); 
    const splitInstructorName = (fullName) => {
        if (!fullName) return { first_name: '', middle_name: '', last_name: '' };
        
        const nameParts = fullName.trim().split(/\s+/);
        
        if (nameParts.length === 1) {
          return {
            first_name: nameParts[0],
            middle_name: '',
            last_name: ''
          };
        }
        
        if (nameParts.length === 2) {
          return {
            first_name: nameParts[0],
            middle_name: '',
            last_name: nameParts[1]
          };
        }
        
        return {
          first_name: nameParts[0],
          middle_name: nameParts.slice(1, -1).join(' '),
          last_name: nameParts[nameParts.length - 1]
        };
      };
      
      const nameParts = splitInstructorName(data?.instructor_name);
         
    const initialValues={
        instructor_id : data?.iid,
        first_name: nameParts.first_name,
        middle_name: nameParts.middle_name,
        last_name: nameParts.last_name,  
        instructor_name:data?.instructor_name,
        user:data?.user,     
        experience:data?.experience,
        mobile_no:data?.mobile_no,
        email:data?.email,
        modified_remarks:'',
        instructor_short_desc:data?.instructor_short_desc,
        modified_by: localStorage.getItem('username')
    }
    const validationSchema= Yup.object().shape({
        // instructor_name: Yup.string().required().min(3).max(50).label("instructors name"),
        first_name: Yup.string().required().min(3).max(50).label("first name"),
                // middle_name: Yup.string().required().min(3).max(50).label("middle name"),
        last_name: Yup.string().required().min(3).max(50).label("last name"),
        experience:Yup.string().required().min(5).max(100).label("about instructor"),
        instructor_short_desc:Yup.string().required().min(10).max(650).label("about instructor"),
        // mobile_no:Yup.number().required().label("mobile no").min(10).max(10),
        email:Yup.string().email().required(),
        modified_remarks:Yup.string().min(4,"must be more than 4 characters").max(100,"must be less than 100 characters").required().label("Modified Remarks") 
    })
    console.log("initial values",initialValues)
    const handleSubmit = (values,submitProps)=>{
        console.log("values",values)
        dispatch(editInstructor(values))
        submitProps.setSubmitting(false)
        // submitProps.resetForm();
    }
    
  return (
    <FormPopUp openPopup={openPopUP} setOpenPopup={setOpenPopUp} title="Instructor Edit">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting,values})=>(                    
                    <Form>                       
                        {/* <div className='row'>
                        <div className='col-md-4 form-group item'>
                                <label className='required-label'>First Name<span className="errors">*</span>:</label>
                                <FastField type="text" name="first_name" id="first_name" placeholder="first name" className='form-control'/>
                                <ErrorMessage name="first_name" component={TextError} />
                        </div>
                        <div className='col-md-4 form-group item'>
                                <label className='required-label'>Middle Name:</label>
                                <FastField type="text" name="middle_name" id="middle_name" placeholder="middle name" className='form-control'/>
                                <ErrorMessage name="middle_name" component={TextError} />
                        </div>
                        <div className='col-md-4 form-group item'>
                                <label className='required-label'>Last Name<span className="errors">*</span>:</label>
                                <FastField type="text" name="last_name" id="last_name" placeholder="last name" className='form-control'/>
                                <ErrorMessage name="last_name" component={TextError} />
                        </div>
                        </div> */}
                        <div className='form-group item'>
                                <label className='required-label'>Instructor Name<span className="errors">*</span>:</label>
                                <FastField type="instructor_name" name="instructor_name" id="instructor_name" placeholder="instructor_name" className='form-control'/>
                                <ErrorMessage name="instructor_name" component={TextError} />
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Email<span className="errors">*</span>:</label>
                                <FastField type="email" name="email" id="email" placeholder="email" className='form-control'/>
                                <ErrorMessage name="email" component={TextError} />
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Mobile No<span className="errors">*</span>:</label>
                                <FastField type="text" name="mobile_no" id="mobile_no" placeholder="mobile no" className='form-control'/>
                                <ErrorMessage name="mobile_no" component={TextError} />
                        </div>                       
                        <div className='form-group item'>
                                <label className='required-label'> Instructors BIO<span className="errors">*</span>:</label>
                                <FastField as="textarea" rows="7" name="experience" id="experience" placeholder="about program" className='form-control'/>
                                <div className="text-counter">
                                    {values.experience ? values.experience.length : 0}/100
                                </div>
                                <ErrorMessage name="experience" component={TextError} />
                        </div>  
                                <div className='form-group item'>
                                <label className='required-label'>About Instructors<span className="errors">*</span>:</label>
                                <FastField as="textarea" rows="7" name="instructor_short_desc" id="instructor_short_desc" placeholder="about Instructor" className='form-control'/>
                                <div className="text-counter">
                                    {values.instructor_short_desc ? values.instructor_short_desc.length : 0}/650
                                </div>
                                <ErrorMessage name="instructor_short_desc" component={TextError} />
                                </div>
                        <div className='form-group item'>
                                <label className='required-label'>Modified Remarks<span className="errors">*</span>:</label>
                                <FastField type="text" name="modified_remarks" id="modified_remarks" placeholder="Modified Remarks" className='form-control'/>
                                <ErrorMessage name="modified_remarks" component={TextError} />
                        </div>                 
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}Save</button>
                    </Form>                   
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default InstructorEdit