import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import {AuthHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';


const slice = createSlice({
    name: 'lessons',
    initialState: {
        result: [],
        loading: false,       
        error: [],
        getData:[],
        getDataById:[],
        lessonOrderSelect:[],
        
        percentage:0
    },
    reducers:{
        lessonOrderRequested: (lesson,action)=> {
            lesson.loading = true;
            lesson.lessonOrderSelect = []
            
        },
        lessonOrderReceived: (lesson,action)=>{
            lesson.loading = false;        
            lesson.lessonOrderSelect = action.payload;
        },
        
        lessonRequested:(lesson,action)=>{
            lesson.loading = true;
        },
        lessonReceived:(lesson,action)=>{
            lesson.loading = true;
            lesson.result = action.payload;
        },
        lessonRequestFailed:(lesson,action)=>{
            lesson.loading = true;
            lesson.result = action.payload;
            toast.error(action.payload?.msg)
        },
        lessonAddedEdited:(lesson,action)=>{
            lesson.loading = true;
            lesson.result = action.payload;
            toast.success(action.payload?.msg)
        },
        getLesson:(lesson,action)=>{
            lesson.loading = true;
            lesson.getData = action.payload;
        },
        getLessonById:(lesson,action)=>{
            lesson.loading = true;
            lesson.getDataById = action.payload;
        },
        
    }
})

const {
   lessonAddedEdited,
    getLesson,
    getLessonById,
   lessonRequested,
   lessonRequestFailed,
   lessonReceived,  
   lessonOrderReceived,
    lessonOrderRequested,
    
} = slice.actions

export default slice.reducer;
export const loadLessonOrderSelectById = (id) => apiCallBegan({
    url: requests.getLessonOrderSelect+'/'+id,
    headers:AuthHeader,
    onStart: lessonOrderRequested.type,
    onSuccess: lessonOrderReceived.type,
    onError: lessonRequestFailed.type
})

export const loadLesson = () => apiCallBegan({
    url:requests.fetchLesson,
    headers:AuthHeader,
    onStart:lessonRequested.type,
    onSuccess:lessonReceived.type,
    onError: lessonRequestFailed.type
})
export const loadLessonSelect = () => apiCallBegan({
    url:requests.fetchLessonSelect,
    headers:AuthHeader,
    onStart:lessonRequested.type,
    onSuccess:getLesson.type,
    onError: lessonRequestFailed.type
})
export const loadLessonSelectById = (id) => apiCallBegan({
    url:requests.fetchLessonSelectById+'/'+id,
    headers:AuthHeader,
    onStart:lessonRequested.type,
    onSuccess:getLesson.type,
    onError: lessonRequestFailed.type
})
export const getLessonByIds = (id) => apiCallBegan({
    url:requests.getLesson+'/'+id,
    headers:AuthHeader,
    onStart:lessonRequested.type,
    onSuccess:getLessonById.type,
    onError: lessonRequestFailed.type
})
export const viewLessonByIds = (id) => apiCallBegan({
    url:requests.viewLessonDetails+'/'+id,
    headers:AuthHeader,
    onStart:lessonRequested.type,
    onSuccess:getLessonById.type,
    onError: lessonRequestFailed.type
})
export const addLesson = (data) => apiCallBegan({
    url:requests.createLesson,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:lessonRequested.type,
    onSuccess:lessonAddedEdited.type,
    onError: lessonRequestFailed.type
})
export const editLesson = (data) => apiCallBegan({
    url:requests.updateLesson,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:lessonRequested.type,
    onSuccess:lessonAddedEdited.type,
    onError: lessonRequestFailed.type
})