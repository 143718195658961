import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TextError from '../../TextError';
import Card from '../Card/Card';
import * as Yup from 'yup';
import { addLesson } from '../../store/LessonSetup';
import Spinner from '../Layout/Spinner';
import { loadMainTopic } from '../../store/MainTopicSetup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import { loadLessonOrderSelectById } from '../../store/LessonSetup';
function LessonAdd({fetchData,setFetchData}) {
    const params = useParams()
    const [mainTopicId,setMainTopicId] = useState(params?.slug)
    const mainTopic = useSelector(state => state.entities.mainTopic?.result?.data)
    const errors = useSelector(state => state.entities.lesson?.error?.data)
    const loading = useSelector(state => state.entities.lesson?.loading)
    const lessonOrder = useSelector(state => state.entities.lesson?.lessonOrderSelect?.data)
    console.log("lesson_order",lessonOrder)
    const dispatch = useDispatch()
    const order_array =  Array.from({ length: 51 }, (_, index) => index);
    useEffect(()=>{
        dispatch(loadMainTopic())
        dispatch(loadLessonOrderSelectById(params?.slug))
    },[params])
    const initialValues = {
        lesson_title:'',
        subcourse_main_topic_id:mainTopicId,
        lecture_hours:'',
        lesson_order:'',
        created_by:localStorage.getItem('username')
    }
    const validationSchema = Yup.object().shape({
        lesson_title: Yup.string().required().min(3).max(255).label('lesson title'),
        subcourse_main_topic_id: Yup.string().required().label('Main topic id'),
        lecture_hours: Yup.string().required().min(1).max(3).label('lecture hours'),
        lesson_order: Yup.string().required().label('lesson order'),

    })
    const changeData=(prevData)=>setFetchData(!prevData)
    const handleSubmit = (values,submitProps)=>{      
        dispatch(addLesson(values));
        submitProps.setSubmitting(false);
        submitProps.resetForm();
        changeData(fetchData);
    }
    const handleMainTopicChange =  async (mainTopicId)=>{
        setMainTopicId(mainTopicId)
        await dispatch(loadLessonOrderSelectById(mainTopicId))
    }
  return (
      <Card cardTitle="Lessons Add">
          {loading && <LoaderSpinner/>}
          <div className='card-body'>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <div className='form-group item'>
                                <label className='required-label'>Lesson Title<span className="errors">*</span></label>
                                <FastField type="text" name="lesson_title" id="lesson_title" placeholder="lesson title" className='form-control'/>
                                <ErrorMessage name="lesson_title" component={TextError} />
                                {errors?.lesson_title && <div className='errors'>{errors?.lesson_title}</div> }
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Lecture Hours<span className="errors">*</span></label>
                                <FastField type="text" name="lecture_hours" id="lecture_hours" placeholder="lecture hours" className='form-control'/>
                                <ErrorMessage name="lecture_hours" component={TextError} />
                                {errors?.lecture_hours && <div className='errors'>{errors?.lecture_hours}</div> }
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select Parent<span className="errors">*</span></label>
                                <Field as="select" name="subcourse_main_topic_id" id="subcourse_main_topic_id" value={mainTopicId} placeholder="" className='form-control' onChange={(e)=>handleMainTopicChange(e.target.value)}>
                                    <option name="subcourse_main_topic_id" value="">select Options</option>
                                    {
                                        mainTopic && mainTopic?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.topic_title}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="subcourse_main_topic_id" component={TextError} />
                                {errors?.subcourse_main_topic_id && <div className='errors'>{errors?.subcourse_main_topic_id}</div> }
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select Lesson Order<span className="errors">*</span></label>
                                <Field as="select" name="lesson_order" id="lesson_order"  placeholder="" className='form-control' >
                                    <option name="lesson_order" value="">select order</option>
                                    {
                                        lessonOrder && lessonOrder?.map((item,index)=>{
                                            if(typeof item === 'object'){
                                                return(
                                                    <option key={index} value={item?.lesson_order}>{`${item?.lesson_order}-${item?.lesson_title}`}</option>

                                                )
                                            }else{

                                                return <option key={index} value={item}>{`${item}-Lesson`}</option>
                                            }
                                        })
                                    }
                                </Field>
                                <ErrorMessage name="lesson_order" component={TextError} />
                                {errors?.lesson_order && <div className='errors'>{errors?.lesson_order}</div> }
                            </div>
                            <div className='card-footer'>
                            <button className='btn btn-flat btn-success' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}save</button>      
                            </div>
                        </Form>
                    )
                }
            </Formik>
          </div>
      </Card>
  );
}

export default LessonAdd;
