import React, { useState } from 'react';
import MathJax from 'mathjax3-react'
import ReplyDiscussion from './ReplyDiscussion';
import { get_user_mail } from '../../EncDec';
import FormPopUp from '../popup/PopUp';
import UpdateDiscussion from './UpdateDiscussion';
// import './Discussion.css';
function Discussion({discussionData,subtopic_id,lesson_id,maintopic_id,subcourse_slug}) {
    const [showReplies,setShowReplies] = useState(false); 
    const [showSingleReplies,setShowSingleReplies] = useState(false); 
    const [replyIndex,setReplyIndex] = useState(0)
    const [editModal,setEditModal] = useState(false);
    const [comment,setComment] = useState('')
    const handleReplies = (index)=>{
        setShowSingleReplies(false)
        setShowReplies(true)
        setReplyIndex(index)
    }
    const handleSingleReplies = (index)=>{
       console.log("single")
        setShowSingleReplies(true)
        setReplyIndex(index)
    }
    const handleEdit = (item)=>{
        setEditModal(true);
        setComment(item)
    }
    const closeSingleReplies = ()=>{
        setShowSingleReplies(false)
        setShowReplies(true)
    }
    return (
    <div>   
    {
    discussionData?discussionData.map((item,index)=>(
        <div className='row'>
        <div className='col-md-12'>
            <div className='row '>
        <div style={{height:50,width:50,borderRadius:999,borderWidth:3,borderColor:'#42a5ff',backgroundColor:'greenyellow',marginRight:10,display:'flex',alignItems:'center',justifyContent:'center'}}>
            <p style={{fontSize:28,fontWeight:'600'}}>{item?.user_name?item?.user_name.charAt(0):'A'}</p>
        </div>
        <div className='card'>
            <div className='card-body' style={{minWidth:350}}>
                <div className='row'>
                    <label>{item?.user_name?item?.user_name:'Anonymous'}</label>{item?.is_staff &&<i className='fa fa-check' style={{color:'white',fontSize:11,marginTop:5,backgroundColor:'blue',height:15,width:15,borderRadius:999,padding:2}}/>}
                </div>
                <div className='row'>
                <MathJax.Html html={item?.comments}/>
                </div>
                <div className='row'>
                     <span>
                     {
                  item?.posted_date < 60 ? 
                  `${item?.posted_date}s ago`
                  :Math.floor(item?.posted_date/60) < 60 
                  ?`${Math.floor(item?.posted_date/60)} min ago`
                  : Math.floor(item?.posted_date/(60*60))< 24
                  ? `${Math.floor(item?.posted_date/(60*60))} hours ago`
                  :Math.floor(item?.posted_date/(60*60*24)) < 7 
                  ? `${Math.floor(item?.posted_date/(60*60*24))} days ago`
                  :Math.floor(item?.posted_date/(60*60*24*7)) < 52 
                  ?`${Math.floor(item?.posted_date/(60*60*24*7))} weeks ago`
                  :`${Math.floor(item?.posted_date/(60*60*24*7*52))} years ago`  
                  }
                
                     </span>
                     <span style={{color:'#42a5ff',marginLeft:5,cursor:'pointer'}} onClick={()=>item?.children && item?.children.length >0?handleReplies(index):handleSingleReplies(index)}>reply</span> 
                     {
                        item?.user_email === get_user_mail()?
                    <span style={{color:'#42a5ff',marginLeft:5,cursor:'pointer'}} onClick={()=>handleEdit(item)}>edit</span> 
                     :''
                     }
                </div>
            </div>
            </div>
        </div>
        </div>
        {
            (showSingleReplies && replyIndex === index)?
            <div className='row' style={{marginLeft:75,marginBottom:10}}>
            <ReplyDiscussion 
                subtopic_id={subtopic_id}
                lesson_id={lesson_id}
                parentId={item?.iid}
                subcourse_slug={subcourse_slug}
                main_topic_id={maintopic_id}
                key={subtopic_id}
                handleCloseSingleReplies={closeSingleReplies}
                />
                </div>
                :''                
        }
        {
            item?.children && item?.children.length > 0 ?
            <div className='row' style={{marginLeft:75,marginBottom:10}}>
                {(showReplies && replyIndex === index)?
                    <>
                    {
                item?.children.map((childItem,chindex)=>(
                    <div className='row' key={chindex}>
                    <div style={{height:50,width:50,borderRadius:999,borderWidth:3,borderColor:'#42a5ff',backgroundColor:'greenyellow',marginRight:10,display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <p style={{fontSize:28,fontWeight:'600'}}>{childItem?.user_name?childItem?.user_name.charAt(0):'A'}</p>
                    </div>
                    <div className='card'>
                        <div className='card-body' style={{minWidth:350}}>
                            <div className='row'>
                            <label>{childItem?.user_name?childItem?.user_name:'Anonymous'}</label>{childItem?.is_staff &&<i className='fa fa-check' style={{color:'white',fontSize:11,marginTop:5,backgroundColor:'blue',height:15,width:15,borderRadius:999,padding:2}}/>}
                            </div>
                            <div className='row'>
                            <MathJax.Html html={childItem?.comments}/>
                            </div>
                            <div className='row'>
                            <span>
                 {
              childItem?.posted_date < 60 ? 
              `${Math.floor(childItem?.posted_date)}s ago`
              :Math.floor(childItem?.posted_date/60) < 60 
              ?`${Math.floor(childItem?.posted_date/60)} min ago`
              : Math.floor(childItem?.posted_date/(60*60))< 24
              ? `${Math.floor(childItem?.posted_date/(60*60))} hours ago`
              :Math.floor(childItem?.posted_date/(60*60*24)) < 7 
              ? `${Math.floor(childItem?.posted_date/(60*60*24))} days ago`
              :Math.floor(childItem?.posted_date/(60*60*24*7)) < 52 
              ?`${Math.floor(childItem?.posted_date/(60*60*24*7))} weeks ago`
              :`${Math.floor(childItem?.posted_date/(60*60*24*7*52))} years ago`  
              }
            
                 </span>
                {childItem?.user_email === get_user_mail() ?<span style={{color:'#42a5ff',marginLeft:5}}>edit</span>:''}
                            </div>
                        </div>
                    </div>
                </div> 
                ))
            }
                <ReplyDiscussion 
                subtopic_id={subtopic_id}
                lesson_id={lesson_id}
                parentId={item?.iid}
                subcourse_slug={subcourse_slug}
                main_topic_id={maintopic_id}
                key={subtopic_id}
                />
                </>
                :<span onClick={()=>handleReplies(index)} style={{cursor:'pointer'}}>{`Replies(${item?.children.length})`}</span>
                }   
            </div>
            :''
        }
    </div>
    ))
    :
    ''
    }
    {
        editModal &&
        <FormPopUp openPopup={editModal} setOpenPopup={setEditModal}>
            <UpdateDiscussion subtopic_id={subtopic_id} item={comment}/>
        </FormPopUp>
    }
</div>
  )
}

export default Discussion