import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { useFormikContext } from 'formik';

const JoditEditors = ({ name,content,setContent }) => {
	const editor = useRef(null);
	// const [content, setContent] = useState('');

	// const config = useMemo(
	// 	{
	// 		readonly: false, // all options from https://xdsoft.net/jodit/docs/,
	// 		placeholder: placeholder || 'Start typings...'
	// 	},
	// 	[placeholder]
	// );
	const {setFieldValue,values}= useFormikContext()
	return (
		<JoditEditor
			ref={editor}
			value={values[name]}
			// config={config}
			tabIndex={1} // tabIndex of textarea
			// onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={e => setFieldValue(name,e)}
		/>
	);
};

export default JoditEditors;