import React from 'react'
import {baseUrl} from '../../axios'
import './ImageViewer.css';
function ImageViewer({image}) {
    // console.log("baseurl",baseUrl)
    // let imageSrc = baseUrl + image
    const hasQuery = image.includes('?');
    return (
        <div className="image_container">
            
            {(image && hasQuery) ?
            <img src={`${image.split('?')[0]}`} alt="avtar" style={{objectFit:"contain",height:'200px',width:'200px'}}/>
            :
            <img src={`${process.env.REACT_APP_BASE_MEDIA_URL}${image}`} alt="avtar" style={{objectFit:"contain",height:'200px',width:'200px'}}/>
        }
            
        </div>
    )
}

export default ImageViewer
