import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import { AuthHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';

const slice = createSlice({
    name: 'course_program',
    initialState: {
        result: [],
        getData: [],
        loading: false,
        popup: false,
        error: []
    },
    reducers:{
    courseRequested: (course_program,action)=> {
        course_program.loading = true;
        
    },
    courseReceived: (course_program,action)=>{
        course_program.loading = false;        
        course_program.result = action.payload;
    },
    courseRequestFailed: (course_program,action) => {
        course_program.loading = false;
        course_program.error = action.payload;
        toast.error(action.payload.msg);
    },
    courseAdded: (course_program,action) => {
        // action.payload
        course_program.loading = false;        
        course_program.result = action.payload;
        course_program.error = [];
        toast.success(action.payload.msg);

    },
    getCourse: (course_program,action) => {
        course_program.loading = false;
        course_program.getData = action.payload;
    },   
    }
})


const {
    courseAdded,
    getCourse,    
    courseReceived,
    courseRequested,
    courseRequestFailed,    
} = slice.actions
export default slice.reducer;

export const loadProgramOverview = () => apiCallBegan({
    url: requests.fetchProgramOverview,
    headers:AuthHeader,
    onStart: courseRequested.type,
    onSuccess: courseReceived.type,
    onError: courseRequestFailed.type
})
export const loadProgramOverviewById = (id) => apiCallBegan({
    url: requests.fetchProgramOverviewById+'/'+id,
    headers:AuthHeader,
    onStart: courseRequested.type,
    onSuccess: getCourse.type,
    onError: courseRequestFailed.type
})

 export const addProgramOverview = (course) => apiCallBegan({
     url: requests.createProgramOverview,
     method:"post",
     headers: AuthHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type
 })
 export const getProgramOverviewByCourseId = (course) => apiCallBegan({
     url: requests.getProgramOverviewByCourseId,
     method:"post",
     headers: AuthHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseReceived.type,
     onError: courseRequestFailed.type
 })

 
 export const editProgramOverview = (course) => apiCallBegan({
     url: requests.editProgramOverview,
     method:"patch",
     headers: AuthHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type
 })
export const loadFAQ = () => apiCallBegan({
    url: requests.fetchFAQ,
    headers:AuthHeader,
    onStart: courseRequested.type,
    onSuccess: courseReceived.type,
    onError: courseRequestFailed.type
})
export const loadFAQById = (id) => apiCallBegan({
    url: requests.fetchFAQById+'/'+id,
    headers:AuthHeader,
    onStart: courseRequested.type,
    onSuccess: getCourse.type,
    onError: courseRequestFailed.type
})

 export const addFAQ = (course) => apiCallBegan({
     url: requests.createFAQ,
     method:"post",
     headers: AuthHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type
 })

 
 export const editFAQ = (course) => apiCallBegan({
     url: requests.editFAQ,
     method:"patch",
     headers: AuthHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type
 })
 