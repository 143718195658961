import React,{useState,useEffect} from 'react'
import {Formik,Form,Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import axios from '../../axios'
import { Button} from '@material-ui/core'
import TextError from '../../TextError'
import { useDispatch, useSelector } from 'react-redux'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import { useParams } from 'react-router-dom';
import {editSubCourse, getSubCourseBySlug} from '../../store/SubCourseSetup';
import Card from '../Card/Card'
import request from '../../ApiRequests/Request'

function SubCourseEdit(props) {
    const {setFetchData,fetchData} = props
    const [courses,setCourses] = useState([])
    // const [subcourse,setSubCourse] = useState([])
    const errors = useSelector(state => state.entities.subcourse.error);
    const subcourse = useSelector(state=> state.entities.subcourse.result);
    const isLoading = useSelector(state=> state.entities.subcourse.loading);
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(()=>{
        async function getCourse(){
            const result = await axios.get(request.fetchCourse)
            setCourses(result.data?.data)
        }
         getCourse()
        // async function getSubCourse(){
        //     const result = await axios.get(`/api/get_subcourse_by_id/${subcourse_id}`)
        //     setSubCourse(result.data.data)
        // }
        dispatch(getSubCourseBySlug(params.slug));
       
        // getSubCourse()

    },[params])
    const validationSchema = Yup.object().shape({
        subcourse_name: Yup.string().min(3,'must contain more than 3 characters').max(50,'must contain less than 50 characters').required('must be filled'),
        subcourse_code: Yup.string().min(2,'must have more than 3 characters').max(7,'cannot exceeds from 7 characters').required('This field is required'),
        courses: Yup.string().required('courses must be filled'),
        // subcourse_preview_url:Yup.string().url().required().label('subcourse preview url'),
        subcourse_short_description:Yup.string().required('Banner description is required').min(30).label('subcourse short description'),
        lecture_hours:Yup.string().required('lecture hours is required').min(1).max(3).label('lecture hours'),
        modified_remarks:Yup.string().min(4,"must be more than 4 characters").max(100,"must be less than 100 characters").required().label("Modified Remarks") 
    })
    const initialValues = {
        subcourse_name: subcourse?.data?.subcourse_name,
        subcourse_code:subcourse?.data?.subcourse_code,
        courses: subcourse?.data?.course_id,
        lecture_hours:subcourse?.data?.lecture_hours,
        subcourse_short_description:subcourse?.data?.subcourse_short_description,
        subcourse_preview_url:subcourse?.data?.subcourse_preview_url,
        modified_remarks:'',
        modified_by : localStorage.getItem('username'),
        subcourse_id : subcourse?.data?.iid
    }
    const changeData = (prevData )=> setFetchData(!prevData)
    const handleSubmit = (values,submitProps)=>{        
        values.subcourse_id = subcourse?.data?.iid
        dispatch(editSubCourse(values));
        submitProps.setSubmitting(false);
        changeData(fetchData);  
    }
    return (
        <>
          <div className="row">
              <div className="col-md-12">
                  <Card cardTitle="Subcourse Edit">
                    <Formik validationSchema={validationSchema} onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
                        {(formik)=>(
                            <Form>
                                <div className='card-body'>
                                <div className="form-group item">
                                    <label className="required-label">Subcourse Name:</label>
                                    <Field type="text" name="subcourse_name" id="subcourse_name" placeholder="Subcourse name" className="form-control" />
                                    <ErrorMessage name="subcourse_name" component={TextError}/>
                                    <div className="errors">{errors?.data?.subcourse_name && errors?.data.subcourse_name}</div>
                                </div>
                                  <div className="form-group item">
                                    <label className="required-label">SubCourse Code <span className="errors">*</span>:</label>
                                     <Field type="text" name = "subcourse_code" id="subcourse_code" placeholder = "Subcourse code"  className="form-control"  />
                                     <ErrorMessage name="subcourse_code" component={TextError}/>
                                    <div className="errors">{errors?.data?.subcourse_code && errors?.data.subcourse_code}</div>
                                </div> 
                                <div className="form-group item">
                            <label className="required-label">SubCourse Preview Url :</label>
                            <Field type="text" name = "subcourse_preview_url" id="subcourse_preview_url" placeholder = "url https://"  className="form-control"  />
                            <ErrorMessage name="subcourse_preview_url" component={TextError}/>
                             <div className="errors">{errors?.data?.subcourse_preview_url && errors?.data.subcourse_preview_url}</div>
                         </div> 
                        <div className="form-group item">
                            <label className="required-label">Lecture Hours <span className="errors">*</span>:</label>
                            <Field type="text" name = "lecture_hours" id="lecture_hours" placeholder = "lecture hours"  className="form-control"  />
                            <ErrorMessage name="lecture_hours" component={TextError}/>
                             <div className="errors">{errors?.data?.lecture_hours && errors?.data.lecture_hours}</div>
                         </div> 
                        <div className="form-group item">
                            <label className="required-label">SubCourse Description <span className="errors">*</span>:</label>
                            <Field as="textarea" rows="6" name = "subcourse_short_description" id="subcourse_short_description" placeholder = "short description"  className="form-control"  />
                            <ErrorMessage name="subcourse_short_description" component={TextError}/>
                             <div className="errors">{errors?.data?.subcourse_short_description && errors?.data.subcourse_short_description}</div>
                         </div> 
                                <div className="form-group item">
                                    <label className="required-label">Select Course</label>
                                    <Field 
                                    name="courses"                                
                                    as="select"
                                    className="form-control"                                   
                                    >
                                        {courses.map((item)=>(
                                            <option key={item.id} value={item.id}>{item.course_name}</option>
                                        ))} 
                                    </Field>

                                </div>
                                <div className="form-group item">
                                <label className="required-label">Modified Remarks <span className="errors">*</span>:</label>
                                <Field type="text" name = "modified_remarks" id="modified_remarks" placeholder = "modified remarks"  className="form-control" />
                                <ErrorMessage name="modified_remarks" component={TextError}/>
                                {errors?.data?.modified_remarks && <div className="errors">{errors?.data?.modified_remarks}</div>}
                                </div> 
                                <div className="card-footer">
                                    <Button type="submit" color="primary" variant="contained" disabled={formik.isSubmitting}>Save</Button>
                                </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                  </Card>
              </div>
            </div> 
            {isLoading?<LoaderSpinner/>:null} 
        </>
    )
}

export default SubCourseEdit
