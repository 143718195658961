import React, { useEffect, useState } from 'react'
import FormPopUp from '../popup/PopUp'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import TextError from '../../TextError'
import { useDispatch, useSelector } from 'react-redux'
import { createArticles } from '../../store/ArticlesStore';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import ImageUploader from '../Form/ImageUploader'
import CkeditorField from '../Form/CkeditorField'
import Spinner from '../Layout/Spinner'
import { loadCourseSelect } from '../../store/CourseSetup'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import * as Yup from 'yup'
import JoditEditors from '../Form/JoditEditors'
import QuillEditor from '../Form/QuillEditor'
function AddArticle({openPopup,setOpenPopup}) {

    const [courses,setCourse] = useState()
    const [year,setYear] = useState()
    const course = useSelector(state => state.entities.courses?.result?.data);
    const dispatch = useDispatch()
    const handleCourseClick = (id)=>{
        setCourse(id)
    }
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const handleSubmit = async (values,submitProps)=>{
        let formData = new FormData();
        formData.append('article_title',values.article_title)
        formData.append('courses',values.courses)
        formData.append('author_name',values.author_name)
        formData.append('article_banner',values.article_banner,values.article_banner.name)
        formData.append('publish_date_nep',values.publish_date_nep)
        formData.append('article',values.article)
        await dispatch(createArticles(formData))
        submitProps.setSubmitting(false)
    }
    const handleDate = ({bsDate})=>{
        setYear(bsDate)
    }
    const initialValues = {
        article_title:'',
        article_banner:'',
        article:'',
        author_name:'',
        courses:courses,
        description_editor_type:'',
        publish_date_nep: year
    }
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ];
      const DESCRIPTION_TYPE = [
        {
            'value':'ck',
            'label':'ck',
        },
        {
            'value':'quill',
            'label':'quill',
        },
        {
            'value':'jodit',
            'label':'jodit',
        },
        
    ]
    const validationSchema = Yup.object().shape({
        article_title: Yup.string().required().min(10).max(100).label("article title"),
        article_banner: Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("article banner"),
        article: Yup.string().required(),
        author_name: Yup.string().required().min(5).max(100),
        publish_date_nep: Yup.date().required('date is required')
    })
    return (
    // <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <BodyContainer pageTitle={`Write & Publish Articles`}  buttonTitle={`cancel`} route={`/articleSetup`}>
        <div className='card'>
        <div className='card-body'>
        <div className='row '>
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        >
            {
                ({isSubmitting,values})=>(
                    <Form>
                        <div className='row'>
                            <div className='row col-md-12'>
                            <div className='form-group col-md-10'>
                            <label>title:<span className='errors'>*</span></label>
                            <Field name="article_title" className="form-control" placeholder="article title here..."/>
                            <ErrorMessage name='article_title' component={TextError}/>
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-3'>
                            <label className='required-label'> select course<span className="errors">*</span></label>
                            <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                    <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                ))
                                        }
                            </Field>
                            <ErrorMessage name="courses" component={TextError} />
                            </div>
                            <div className='form-group col-md-3'>
                            <label>publish Date(BS)<span className="errors">*</span></label>
                                <Calendar
                                language="en"
                                theme="default"
                                name={`publish_date_nep`}
                                className="form-control"
                                onChange={handleDate}
                                defaultDate={initialValues.publish_date_nep}
                                />
                                <ErrorMessage name="publish_date_nep" component={TextError}/>
                            </div>
                            <div className='form-group col-md-3'>
                                <label>author:<span className='errors'>*</span></label>
                                <Field name="author_name" className="form-control" placeholder="author name"/>
                                <ErrorMessage name='author_name' component={TextError}/>
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-2'>
                                <ImageUploader
                                name="article_banner"
                                label="article banner"
                                required={'required'}
                                accept="image/jpg, image/gif,image/jpeg,image/png"                       
                                />
                            </div>
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Description Editor Type<span className='errors'>*</span> </label>
                                <Field as="select" name="description_editor_type" id="description_editor_type" placeholder="" className='form-control'>
                                    <option name="description_editor_type" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        DESCRIPTION_TYPE && DESCRIPTION_TYPE?.map((item,index)=>(
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="description_editor_type" component={TextError} />
                                {/* {errors?.description_editor_type && <div className='errors'>{errors?.description_editor_type}</div>} */}
                            </div> 
                        <div className='row col-md-12'>
                        {
                                values.description_editor_type === 'ck' &&
                                <div className='form-group item'>
                                    <label>Article{values.page_type === 'plain_text'?<span className='errors'>*</span>:''}</label>
                                <CkeditorField name="article" />
                                <ErrorMessage name='article' component={TextError}/>
                                {/* {errors?.article && <div className='errors'>{errors?.article}</div>} */}
                                </div>

                            } 
                            {
                                values.description_editor_type === 'quill' &&
                                <div className='form-group item'>
                                    <label>Article{values.page_type === 'plain_text'?<span className='errors'>*</span>:''}</label>
                                <QuillEditor  name="article" />
                                <ErrorMessage name='article' component={TextError}/>
                                {/* {errors?.article && <div className='errors'>{errors?.article}</div>} */}
                                </div>
                            }                       
                            {
                                values.description_editor_type === 'jodit' &&
                                <div className='form-group item'>
                                    <label>Article{values.page_type === 'plain_text'?<span className='errors'>*</span>:''}</label>
                                <JoditEditors name="article" />
                                <ErrorMessage name='article' component={TextError}/>
                                {/* {errors?.article && <div className='errors'>{errors?.article}</div>} */}
                                </div>
                            } 
                        
                        </div>
                        </div>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}>
                            {isSubmitting && <><Spinner/>&nbsp;</>} publish
                        </button>
                        
                    </Form>
                )
            }
        </Formik>
            </div>
        </div>
        </div>
        </BodyContainer>
    // </FormPopUp>
  )
}

export default AddArticle