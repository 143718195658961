import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function QuillEditor({name,value,setValue}) {
  // const [value, setValue] = useState('');
  const {setFieldValue,values} = useFormikContext()
  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ 'color': [] }, { 'background': [] }],   // dropdown with defaults from theme
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}], // outdent/indent
      ['blockquote', 'code-block'],
      ['link', 'image', 'video'],
      ['clean'],  // remove formatting button
    ],
  };
  

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'image', 'video', 'file', 'link',"code-block", "video", "blockquote", "clean"
];
  return (
    <div>
       
        <ReactQuill 
        theme="snow" 
        value={values[name]} 
        onChange={(e)=>setFieldValue(name,e)} 
        modules={modules}
        // formats={formats}
        />
    </div>
);

}
export default QuillEditor;